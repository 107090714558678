<template>
  <div>
    <navigation-view />
    <sticky-view />

    <section class="m-auto justify-center items-center">
      <div class="w-full mx-auto relative object-cover bg-center bg-no-repeat">
        <img
          src="../assets/BCA-Animation-and-Graphic-Design-desktop.webp"
          class="hidden md:block w-full"
        />
        <img
          src="../assets/BCA-Animation-and-Graphic-Design-Mobile.webp"
          class="md:hidden"
        />
        <div class="max-w-7xl mx-auto flex place-items-center">
          <div
            class="absolute text-white text-center lg:text-left mx-auto lg:top-[18%] top-3"
          >
            <div class="lg:ml-[20%]">
              <h1
                class="lg:text-4xl lg:pt-2 pt-12 py-2 font-extrabold text-[20px] sm:text-[20px]"
              >
                BCA with Specialization in <br />Animation and Graphic Design
              </h1>

              <h2
                class="text-white lg:text-lg text-[12px] lg:w-[70%] pb-4 lg:text-left text-center md:ml-0 m-auto"
              >
                Become an architect of the Animation Future with our Online BCA
                in Animation and Graphic Design
              </h2>
            </div>

            <div class="flex mt-6 md:hidden">
              <div class="w-full h-[1px] bg-gradient-to-l from-white"></div>
              <div class="w-full h-[1px] bg-gradient-to-r from-white"></div>
            </div>
            <div
              class="flex flex-row text-sm bg-gradient-to-r from-[#7ab422] from-0% via-[#72a821] via-80% to-90% to-[#7ab422] md:text-base lg:ml-48 lg:w-[50%]"
            >
              <div
                class="w-[40%] flex justify-center py-2 border-white border-r"
              >
                <span class="flex items-center text-lg font-bold md:gap-2">
                  <img
                    src="@/assets/Programpage/banner/banner-3-Years.png"
                    class="mr-2"
                    width="30"
                    alt
                  />
                  3 Years
                </span>
              </div>
              <div class="w-[60%] flex justify-center py-2">
                <span class="flex items-center text-lg font-bold md:gap-2">
                  <img
                    src="@/assets/Programpage/banner/banner-Total-Credits--140.png"
                    class="mr-2"
                    width="30"
                    alt
                  />
                  Live Online Classes
                </span>
              </div>
            </div>
            <div class="flex mb-6">
              <div class="w-full h-[1px] bg-gradient-to-l from-white"></div>
              <div class="w-full h-[1px] bg-gradient-to-r from-white"></div>
            </div>
            <div
              class="flex gap-4 place-content-center lg:place-content-start mb-4 md:ml-[20%]"
            >
              <a
                href="https://apply.onlineyenepoya.com/"
                target="_blank"
                class="px-6 py-2 bg-[#82C8DE] font-bold rounded-lg shadow-xl"
                >Apply Now</a
              >
              <div @click="is_widget = true">
                <button
                  class="px-6 py-2 bg-[#82C8DE] font-bold rounded-lg shadow-xl"
                  href="https://api.onlineyenepoya.com/media/BCA-in-Animation-and-Graphics-Design_15043.pdf"
                  target="_blank"
                >
                  Download Brochure
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="max-w-7xl mx-auto p-4">
      <h2
        class="text-center lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold lg:p-4 p-1"
      >
        About Specialization - Animation and Graphic Design
      </h2>

      <!-- <h6
          class="text-center lg:text-xl text-[20px] sm:text-[20px] text-gray-700"
        >International Finance and Accounting</h6>-->
      <div class="text-left text-justify readmore-section p-4">
        <p>
          The Bachelor of Computer Applications (BCA) in Animation and Graphic
          Design is a specialized undergraduate program that blends the
          technical skills of computer applications with creative artistry in
          animation and graphic design. This course is designed for students who
          are passionate about both technology and creativity, equipping them
          with the skills needed to excel in the dynamic fields of animation,
          visual effects, game design, and multimedia production.
        </p>
        <p class="paragraph hidden">
          The program emphasizes a hands-on approach to learning, encouraging
          students to engage with cutting-edge tools and software used by
          industry professionals. With a curriculum that balances theory with
          practical experience, students will gain a comprehensive understanding
          of digital design, 2D and 3D animation, interactive media, and UI/UX
          development. Graduates will be well-prepared to enter the creative and
          technology-driven world of digital media, bringing imaginative ideas
          to life through innovative solutions.
        </p>
        <!-- <p>
            <strong
              >UGC Entitled | Online Degree | NAAC ‘A+’ Grade Accredited |
              Placement Support</strong
            >
          </p>
          <p>
       <strong>
              For further information, please contact us at <a href="tel:07666830000">07666830000 </a> or email us at <a href="mailto:meghana@onlineyenepoya.com">meghana@onlineyenepoya.com</a>
            </strong>
          </p> -->

        <p class="flex align-middle place-content-end gap-2">
          <button class="readmore-btn text-gray-600">Read More</button>
          <img src="../assets/program/read-more.png" width="25" alt />
        </p>
      </div>
    </section>

    <section
      class="xl:flex lg:gap-12 max-w-7xl mx-auto p-4 text-center lg:flex-row flex flex-col-reverse xl:text-left"
    >
      <div class="lg:w-1/2">
        <h1
          class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold lg:py-6 py-4"
        >
          Program Highlights
        </h1>
        <div>
          <ul class="divide-y text-left">
            <li class="flex gap-4 p-2">
              <img
                src="../assets/Programpage/Eligibility/blue_bullet.webp"
                alt
                class="h-5"
              />
              <p>
                <strong>Dual Focus: </strong>
                Combines computer applications with creative animation and
                graphic design.
              </p>
            </li>

            <li class="flex gap-4 p-2">
              <img
                src="../assets/Programpage/Eligibility/blue_bullet.webp"
                alt
                class="h-5"
              />
              <p>
                <strong>Hands-On Learning: </strong>
                Practical experience with industry-standard tools like Adobe,
                Maya, and Unity.
              </p>
            </li>

            <li class="flex gap-4 p-2">
              <img
                src="../assets/Programpage/Eligibility/blue_bullet.webp"
                alt
                class="h-5"
              />
              <p>
                <strong> Comprehensive Curriculum: </strong>
                Covers 2D/3D animation, UI/UX design, and game development.
              </p>
            </li>

            <li class="flex gap-4 p-2">
              <img
                src="../assets/Programpage/Eligibility/blue_bullet.webp"
                alt
                class="h-5"
              />
              <p>
                <strong>Real-World Exposure: </strong>
                Includes projects, workshops, and internships for industry-ready
                skills.
              </p>
            </li>
            <li class="flex gap-4 p-2">
              <img
                src="../assets/Programpage/Eligibility/blue_bullet.webp"
                alt
                class="h-5"
              />
              <p>
                <strong>Career-Driven Approach: </strong>
                Prepares students for diverse roles in digital media and
                creative industries: Includes projects, workshops, and
                internships for industry-ready skills.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="border-r m-4 hidden lg:block"></div>
      <div class="lg:w-1/2">
        <h2
          class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold lg:py-6 py-4"
        >
          Eligibility
        </h2>
        <ul class="divide-y text-left">
          <li class="flex gap-4 p-2">
            <img
              src="../assets/Programpage/Eligibility/blue_bullet.webp"
              alt
              class="h-5"
            />
            <p>
              Successfully clear 10th and 12th examinations accredited by either
              State Board, CBSE, NIOS, IGCSE, IB, ICSE, recognized by respective
              State or Central Government students who are scheduled to take the
              10th or 12th examinations are eligible to submit their
              application.
            </p>
          </li>
        </ul>
      </div>
    </section>
    <section class="p-4 lg:py-8 py-2 max-w-7xl mx-auto">
      <h2
        class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 font-bold text-center lg:p-4 p-0"
      >
        Modules
      </h2>
      <div class="lg:flex lg:gap-4">
        <div>
          <div data-accordion="collapse" class>
            <h2 id="accordion-collapse-heading-7" class="pt-4 moreBox1">
              <button
                class="flex items-center text-left justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b6"
                data-accordion-target="#accordion-collapse-body-7"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-7"
              >
                <span class="text-black">Semester 1  <span class="font-normal"> (Credit - 24) </span></span>
                <img src="../assets/about/read-more.png" width="20" alt />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-7"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-7"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <ul class="divide-y text-left">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">English</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Programming in C*</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Fundamentals of Mathematics</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Drawing and Sketching</p>
                  </li>

                  <li class="flex gap-4 p-2 text-left items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Design Principles and Color Theory</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Designing in 2D</p>
                  </li>
                </ul>
              </div>
            </div>
            <h2 id="accordion-collapse-heading-8" class="pt-4 moreBox1">
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b7"
                data-accordion-target="#accordion-collapse-body-8"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-8"
              >
                <span class="text-black">Semester 2  <span class="font-normal"> (Credit - 24) </span></span>
                <img src="../assets/about/read-more.png" width="20" alt />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-8"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-8"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <ul class="divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Illustrative Designing</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Data structures</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Designing Creative Text and 2D-Cartoons</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">OOPS with C++*</p>
                  </li>

                  <li class="flex gap-4 p-2 text-left items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Photography and Audio/Video Editing</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Communicative English</p>
                  </li>
                </ul>
              </div>
            </div>
            <h2 id="accordion-collapse-heading-9" class="pt-4 moreBox1">
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b8"
                data-accordion-target="#accordion-collapse-body-9"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-9"
              >
                <span class="text-black">Semester 3  <span class="font-normal"> (Credit - 20) </span></span>
                <img src="../assets/about/read-more.png" width="20" alt />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-9"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-9"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <ul class="divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">3D Modelling, Animation and Compositing</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Database Management Systems*</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">3D Sculpting</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Rotoscopy</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Environmental Science</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div data-accordion="collapse">
            <h2
              id="accordion-collapse-heading-10"
              class="pt-4 moreBox1 lg:block hidden"
            >
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b9"
                data-accordion-target="#accordion-collapse-body-10"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-10"
              >
                <span class="text-black">Semester 4  <span class="font-normal"> (Credit - 20) </span></span>
                <img src="../assets/about/read-more.png" width="20" alt />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-10"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-10"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <ul class="divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Fluid Dynamics, Simulation and Texturing</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Understanding Motion Graphics</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Data Mining</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">3D Conversion and Advanced Visual Effects</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Ethics and Values</p>
                  </li>
                </ul>
              </div>
            </div>
            <h2
              id="accordion-collapse-heading-11"
              class="pt-4 moreBox1 lg:block hidden"
            >
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b10"
                data-accordion-target="#accordion-collapse-body-11"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-11"
              >
                <span class="text-black">Semester 5  <span class="font-normal"> (Credit - 20) </span></span>
               
                <img src="../assets/about/read-more.png" width="20" alt />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-11"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-11"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <ul class="divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Entrepreneurship</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Object Oriented Modeling and Design Patterns</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Concept of UI/UX Design and Wireframing</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Cross-platform UI/UX Development-1</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Create, Share & Test Designs with Cloud UI/UX Tool</p>
                  </li>
                </ul>
              </div>
            </div>
            <h2
              id="accordion-collapse-heading-18"
              class="pt-4 moreBox1 lg:block hidden"
            >
              <button
                class="flex items-center justify-between w-80 md:w-[480px] xl:w-[600px] mx-auto p-5 border border-gray-200 rounded-xl bg-white dark:bg-white"
                id="b11"
                data-accordion-target="#accordion-collapse-body-18"
                aria-expanded="false"
                aria-controls="accordion-collapse-body-18"
              >
                <span class="text-black">Semester 6  <span class="font-normal"> (Credit - 24) </span></span>
                <img src="../assets/about/read-more.png" width="20" alt />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-18"
              class="hidden"
              aria-labelledby="accordion-collapse-heading-18"
            >
              <div
                class="p-5 border border-t-0 border-gray-200 w-80 md:w-[480px] xl:w-[600px] mx-auto rounded-b-xl bg-white"
              >
                <ul class="divide-y">
                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Internet of Things</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Gaming & Metaverse, NFT & Multi-platform Deployment</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Game Design</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p class="text-left">Soft skills, Interview Prep and Profiling Building</p>
                  </li>

                  <li class="flex gap-4 p-2 items-center">
                    <img
                      src="../assets/Programpage/Eligibility/blue_bullet.webp"
                      alt
                      class="h-5"
                    />
                    <p>Project</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="flex text-left justify-center mt-4">
          <div
            class="flex align-middle place-content-end gap-2 lg:hidden"
            id="loadMore1"
          >
            <button class="readmore-btn text-gray-600">
              <a href="#">View More</a>
            </button>
            <img src="../assets/program/read-more.png" width="25" alt />
          </div>
        </div>
      </div>
    </section>

    <section class="max-w-7xl mx-auto lg:py-6 py-2">
      <h1
        class="text-3xl text-gray-700 text-center font-bold p-2 lg:text-3xl text-[20px] sm:text-[20px]"
      >
        Fee Structure
      </h1>
      <div
        class="mx-auto bg-white rounded-lg shadow-lg text-center p-2 lg:w-full w-[90%] sm:w-[90%]"
      >
        <div class="m-4">
          <!-- Tab content -->
          <div class="tabcontent flex flex-col lg:flex-row">
            <div class="w-full">
              <h1
                class="tablinks align-bottom rounded-t-lg py-4 mt-4 px-4 mx-1 text-white font-semibold text-center bg-[#9D9B9C]"
              >
                Domestic fee structure
              </h1>
              <table class="w-full border-4 border-white">
                <tr class="bg-[#94C34D] text-white h-16">
                  <th class="border-4 border-white">Yearly Fee Plan</th>
                  <th class="border-4 border-white">Total Year Fee Plan</th>
                </tr>
                <tr class="bg-[#BDE6F7] h-10 text-center">
                  <td class="border-4 border-white">₹ 25,000</td>
                  <td class="border-4 border-white">₹ 75,000</td>
                </tr>
              </table>
            </div>
            <!-- <div class="w-full">
              <h1
                class="tablinks align-bottom rounded-t-lg py-4 mt-4 px-4 mx-1 text-white font-semibold text-center bg-[#9D9B9C]"
              >
                International fee structure
              </h1>
              <table class="w-full border-4 border-white">
                <tr class="bg-[#94C34D] text-white h-16">
                  <th class="border-4 border-white">Yearly Fee Plan</th>
                  <th class="border-4 border-white">Total Year Fee Plan</th>
                </tr>
                <tr class="bg-[#BDE6F7] h-10 text-center">
                  <td class="border-4 border-white">$ 700</td>
                  <td class="border-4 border-white">$ 2,100</td>
                </tr>
              </table>
            </div> -->
          </div>
        </div>
      </div>
    </section>

    <section
      class="max-w-7xl mx-auto p-2 text-center justify-center object-cover lg:flex-row flex"
    >
      <div class="px-4 lg:py-4 py-1 gap-6 justify-between">
        <h2
          class="lg:text-3xl text-[20px] sm:text-[20px] text-gray-700 text-center font-bold lg:py-6 py-4"
        >
          Career Opportunities
        </h2>

        <div class="text-left text-justify p-4">
          <p>
            The integration of Animation and Graphic Design skills opens up
            diverse career paths in the dynamic IT and Media. Graduates with an
            Online BCA in Animation and Graphic Design can explore exciting
            roles such as:
          </p>
        </div>

        <div
          class="px-4 lg:py-8 py-1 gap-6 flex flex-col lg:flex-row mx-0 justify-center"
        >
          <div class="flex lg:w-[50%] w-full">
            <ul class="text-left">
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Animator (2D/3D)</p>
              </li>
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Graphic Designer</p>
              </li>
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Game Designer/Developer</p>
              </li>
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>UI/UX Designer</p>
              </li>
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Motion Graphics Artist</p>
              </li>
            </ul>
          </div>

          <div class="flex lg:w-[80%] w-full">
            <ul class="text-left">
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Digital Illustrator</p>
              </li>
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Multimedia Specialist</p>
              </li>
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Art Director</p>
              </li>
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Storyboard Artist</p>
              </li>
              <li class="flex gap-4 p-2">
                <img
                  src="../assets/Programpage/Eligibility/blue_bullet.webp"
                  alt
                  class="h-5"
                />
                <p>Character Designer</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section v-if="is_widget">
      <div
        class="fixed inset-0 z-50 overflow-y-auto w-full bg-black bg-opacity-60"
      >
        <div class="flex items-center justify-center min-h-screen">
          <div
            class="relative bg-white rounded-lg h-[300px] w-[450px] z-60 p-4"
          >
            <div
              class="npf_wgts"
              data-height="344px"
              :data-w="this.widget_id2"
            ></div>
            <button
              @click="is_widget = false"
              class="absolute top-0 right-1 bg-white rounded-full p-1 z-50"
            >
              <svg
                class="h-6 w-6 fill-current text-gray-700 hover:text-gray-600 transition duration-150 ease-in-out"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 8.586l4.95-4.95 1.414 1.414L11.414 10l4.95 4.95-1.414 1.414L10 11.414l-4.95 4.95-1.414-1.414L8.586 10 3.636 5.05l1.414-1.414L10 8.586z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>

            <div class>
              <div class="flex items-center justify-center">
                <div class="relative bg-white rounded-lg shadow-sm">
                  <h4 class="font-bold text-lg px-2 py-2">
                    Download Brochure
                    <br />
                  </h4>
                  <div>
                    <form
                      class="space-y-3 p-4 font-normal"
                      @submit.prevent="brochureSubmit"
                    >
                      <input
                        type="text"
                        placeholder="Enter Name *"
                        id="FirstName"
                        class="p-1 rounded-sm w-full border-gray-300"
                        v-model="formInfo.firstName"
                      />
                      <input
                        type="email"
                        placeholder="Enter Email Address *"
                        id="EmailAddress"
                        class="p-1 rounded-sm w-full border-gray-300"
                        v-model="formInfo.email"
                      />

                      <input
                        type="tel"
                        placeholder="Enter Mobile Number *"
                        id="phone"
                        maxlength="10"
                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                        class="p-1 rounded-sm w-full border-gray-300"
                        required
                      />

                      <select
                        name="mx_Program"
                        id
                        value="mx_Program"
                        aria-placeholder="Select Program"
                        class="p-1 rounded-sm w-full border-gray-300 text-base hidden"
                        required
                      >
                        <option value="BCA">BCA</option>
                      </select>
                      <select
                        name="mx_Elective"
                        id="mx_Elective"
                        aria-placeholder="Select Elective"
                        class="p-1 rounded-sm w-full border-gray-300 text-base hidden"
                      >
                        <option value="none">Select Specialization *</option>
                        <option value=" Computer Science and IT">
                          Cloud Computing & Cyber Security
                        </option>
                      </select>

                      <button
                        type="submit"
                        class="w-full text-white bg-[#9C9B9D] hover:bg-[#94C34D] rounded-lg px-4 py-2 text-center"
                      >
                        Download Brochure &#10148;
                      </button>

                      <!-- <button
                          type="submit"
                          class="w-full text-white bg-[#9C9B9D] hover:bg-[#94C34D] rounded-lg px-4 py-2 text-center"
                        >
                          Download Brochure &#10148;
                        </button>-->
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <faq-view />
    <section class="lg:pt-40 pt-8 sm:pt-8 pb-10">
      <div
        class="lg:w-full w-[90%] sm:w-[90%] mx-auto max-w-7xl border-2 border-gray-400 rounded-lg lg:p-16 p-4 relative m-4 sm:p-4"
      >
        <div class="flex flex-col gap-5">
          <div>
            <h3 class="font-bold md:text-[28px] text-lg text-left">
              Have any questions?
            </h3>
            <h3 class="font-bold text-[18px] text-left">
              <br />
              We are just a click away!
            </h3>
          </div>
          <div class="flex flex-col lg:flex-row gap-5">
            <!-- <a href="https://apply.onlineyenepoya.com/" target="_blank"
                  >B.COM</a 
                > -->
            <div class="flex">
              <h5
                class="font-bold lg:text-lg text-[16x] px-2 py-2 rounded-xl border-2"
              >
                <a href="tel:07666830000 " class="flex">
                  <img
                    alt="call"
                    src="../assets/telephone.png"
                    width="25"
                    height="25"
                  />
                  <span class="flex pl-3 pt-2">076668 30000 </span>
                </a>
              </h5>
            </div>

            <div class="flex">
              <h5
                class="font-bold lg:text-lg text-[16px] px-2 py-2 rounded-xl border-2"
              >
                <a
                  href="mailto:meghana@onlineyenepoya.com"
                  target="_blank"
                  class="flex md:pr-3 pr-6"
                  ><img
                    alt="whatsapp"
                    src="../assets/mail.png"
                    width="30"
                    height="30"
                    class="flex"
                  />
                  <span class="flex pl-2 pt-2"
                    >meghana@onlineyenepoya.com
                  </span>
                </a>
              </h5>
            </div>
            <!-- <h5
                class="text-white font-bold lg:text-lg text-[16px] px-2 py-2 bg-[#9C9A9B] rounded-xl border-2"
              >
                <a href="https://apply.onlineyenepoya.com/" target="_blank"
                  >BCA</a
                >
              </h5> -->
          </div>
        </div>
        <div class="absolute right-0 bottom-0">
          <!-- &lt;img src=&quot;../assets/aboutus/join_our_degree.webp&quot; class=&quot;lg:hidden sm:block w-[50%] float-right&quot; alt /&gt; --><img
            src="/img/join_our_degree.7c2c51e9.webp"
            class="lg:block hidden"
            alt=""
          />
        </div>
      </div>
    </section>
    <footer-view />
  </div>
</template>

<script>
import $ from "jquery";
import FaqView from "../components/FaqView.vue";
import FooterView from "../components/FooterView.vue";
import NavigationView from "../components/NavigationView.vue";
import StickyView from "../components/StickyView.vue";

import { useHead } from "@vueuse/head";

import axios from "axios";
export default {
  components: { FaqView, StickyView, FooterView, NavigationView },

  setup() {
    useHead({
      // Can be static or computed
      title: "Online BCA in Animation and Graphic Design | Yenepoya Online",
      meta: [
        {
          name: `description`,
          content:
            "Catalyze your journey with our accredited online BCA program in Animation and Graphic Design Drive innovation and lay the foundation of an exciting career.",
        },
      ],

      link: [
        {
          rel: "canonical",
          href: "https://onlineyenepoya.com/programs/online-bca-animation-and-graphics_design",
        },
      ],
    });
  },

  props: ["widget_prop_id"],
  created() {
    this.widget_id = this.widget_prop_id;
  },
  data() {
    return {
      is_widget: false,
      showMenu: false,
      formInfo: {
        firstName: "",
        email: "",
        phone: "",
      },
    };
  },

  methods: {
    brochureSubmit(el) {
      console.log("formInput:::", el);
      let dataJson = [
        {
          Attribute: "FirstName",
          Value: el.target.elements.FirstName.value,
        },
        {
          Attribute: "EmailAddress",
          Value: el.target.elements.EmailAddress.value,
        },
        {
          Attribute: "Phone",
          Value: el.target.elements.phone.value,
        },
        {
          Attribute: "mx_Program",
          Value: "BCA",
        },

        {
          Attribute: "mx_Elective",
          Value: " Animation and Graphics Design ",
        },
      ];
      console.log(dataJson);
      axios
        .post(
          "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$rca456e8ab1c2865cfece22cda05cbb0c&secretKey=3c0ddb27e7dc562e5c6c08bb8c11b02a9019e041",
          dataJson,
          { cross: true }
        )
        .then(async () => {
          await fetch(
            `https://api.onlineyenepoya.com/media/BCA-in-Animation-and-Graphics-Design_15043.pdf`
          )
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const a = document.createElement("a");
              a.href = url;
              a.download = `BCA-Animation-and-Graphics-Design.pdf`;
              a.click();
            });
          setTimeout(() => {
            window.location.href = "/brochure/bba-thankyou";
          }, 2000);
        })
        .catch((error) => {
          // console.log(error.response.data.ExceptionMessage)
          console.log(error);
        });
    },
  },

  mounted() {
    $(".readmore-btn").on("click", function (e) {
      var target = $(e.target).parents(".readmore-section");
      var paragraph = target.find(".paragraph");

      if (paragraph.hasClass("hidden")) {
        paragraph.removeClass("hidden");
        $(this).text("Read Less");
      } else {
        paragraph.addClass("hidden");
        $(this).text("Read More");
      }
    });

    $(document).ready(function () {
      $(".moreBox1").slice(0, 3).show();
      if ($(".accordion-item:hidden").length != 0) {
        $("#loadMore1").show();
      }
      $("#loadMore1").on("click", function (e) {
        e.preventDefault();
        $(".moreBox1:hidden").slice(0, 4).slideDown();
        if ($(".moreBox1:hidden").length == 0) {
          $("#loadMore1").fadeOut("slow");
        }
      });
    });
  },
};
</script>

<style></style>
