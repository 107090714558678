<template>
  <div>
    <section class="m-auto justify-center items-center">
      <div class="w-full mx-auto relative object-cover bg-center bg-no-repeat">
        <img
          src="@/assets/B.Com-in-Accounting-and-Finance-Desktop.webp"
          class="hidden md:block w-full"
        />
        <img
          src="@/assets/bcom-in-accounting-and-finance-mobile.webp"
          class="md:hidden"
        />
        <div class="max-w-7xl mx-auto flex place-items-center">
          <div
            class="absolute text-white text-center lg:text-left mx-auto lg:top-[18%] -top-6"
          >
            <div class="lg:ml-[16%]">
              <h1
                class="lg:text-4xl lg:pt-2 pt-12 py-2 font-extrabold text-[20px] sm:text-[20px]"
              >
                B.Com with Specialization in <br />Accounting and Finance
              </h1>
              <h2
                class="text-white text-lg w-[80%] lg:text-left text-center md:ml-0 m-auto"
              >
                Chart Your Financial Journey with our Online B.Com Accounting
                and Finance
              </h2>
            </div>
            <div class="flex mt-6">
              <div class="w-full h-[1px] bg-gradient-to-l from-white"></div>
              <div class="w-full h-[1px] bg-gradient-to-r from-white"></div>
            </div>
            <div
              class="flex flex-row text-sm bg-gradient-to-r from-[#7ab422] from-0% via-[#72a821] via-80% to-90% to-[#7ab422] md:text-base lg:ml-32"
            >
              <div
                class="w-[40%] flex justify-center py-2 border-white border-r"
              >
                <span class="flex items-center text-lg font-bold md:gap-2">
                  <img
                    src="@/assets/Programpage/banner/banner-3-Years.png"
                    class="mr-2"
                    width="30"
                    alt
                  />
                  3 Years
                </span>
              </div>
              <div class="w-[60%] flex justify-center py-2">
                <span class="flex items-center text-lg font-bold md:gap-2">
                  <img
                    src="@/assets/Programpage/banner/banner-Total-Credits--140.png"
                    class="mr-2"
                    width="30"
                    alt
                  />
                  Live Online Classes
                </span>
              </div>
            </div>
            <div class="flex mb-6">
              <div class="w-full h-[1px] bg-gradient-to-l from-white"></div>
              <div class="w-full h-[1px] bg-gradient-to-r from-white"></div>
            </div>

            <div
              class="flex gap-4 place-content-center lg:place-content-start mb-4 lg:ml-32"
            >
              <a
                href="https://apply.onlineyenepoya.com/"
                target="_blank"
                class="px-6 py-2 bg-[#82C8DE] font-bold rounded-lg shadow-xl"
                >Apply Now</a
              >

              <div @click="is_widget = true">
                <button
                  class="px-6 py-2 bg-[#82C8DE] font-bold rounded-lg shadow-xl"
                  href="https://api.onlineyenepoya.com/media/B.Com_in_Accounting_and_Finance_63962.pdf"
                  target="_blank"
                >
                  Download Brochure
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="is_widget">
      <div
        class="fixed inset-0 z-50 overflow-y-auto w-full bg-black bg-opacity-60"
      >
        <div class="flex items-center justify-center min-h-screen">
          <div
            class="relative bg-white rounded-lg h-[300px] w-[450px] z-60 p-4"
          >
            <div
              class="npf_wgts"
              data-height="344px"
              :data-w="this.widget_id2"
            ></div>
            <button
              @click="is_widget = false"
              class="absolute top-0 right-1 bg-white rounded-full p-1 z-50"
            >
              <svg
                class="h-6 w-6 fill-current text-gray-700 hover:text-gray-600 transition duration-150 ease-in-out"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 8.586l4.95-4.95 1.414 1.414L11.414 10l4.95 4.95-1.414 1.414L10 11.414l-4.95 4.95-1.414-1.414L8.586 10 3.636 5.05l1.414-1.414L10 8.586z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>

            <div class>
              <div class="flex items-center justify-center">
                <div class="relative bg-white rounded-lg shadow-sm">
                  <h4 class="font-bold text-lg px-2 py-2">
                    Download Brochure
                    <br />
                  </h4>
                  <div>
                    <form
                      class="space-y-3 p-4 font-normal"
                      @submit.prevent="brochureSubmit"
                    >
                      <input
                        type="text"
                        placeholder="Enter Name *"
                        id="FirstName"
                        class="p-1 rounded-sm w-full border-gray-300"
                        v-model="formInfo.firstName"
                      />
                      <input
                        type="email"
                        placeholder="Enter Email Address *"
                        id="EmailAddress"
                        class="p-1 rounded-sm w-full border-gray-300"
                        v-model="formInfo.email"
                      />

                      <input
                        type="tel"
                        placeholder="Enter Mobile Number *"
                        id="phone"
                        maxlength="10"
                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                        class="p-1 rounded-sm w-full border-gray-300"
                        required
                      />

                      <select
                        name="mx_Program"
                        id
                        value="mx_Program"
                        aria-placeholder="Select Program"
                        class="p-1 rounded-sm w-full border-gray-300 text-base hidden"
                        required
                      >
                        <option value="B.Com">B.Com</option>
                      </select>
                      <select
                        name="mx_Elective"
                        id="mx_Elective"
                        aria-placeholder="Select Elective"
                        class="p-1 rounded-sm w-full border-gray-300 text-base hidden"
                      >
                        <option value="none">Select Specialization *</option>
                        <option value=" Accounting and Finance">
                          Accounting and Finance
                        </option>
                      </select>

                      <!-- <button
        type="submit"
        class="w-full text-white bg-[#9C9B9D] hover:bg-[#94C34D] rounded-lg px-4 py-2 text-center"
      >Download Brochure &#10148;</button> -->

                      <button
                        type="submit"
                        class="w-full text-white bg-[#9C9B9D] hover:bg-[#94C34D] rounded-lg px-4 py-2 text-center"
                      >
                        Download Brochure &#10148;
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["widget_prop_id"],
  created() {
    this.widget_id = this.widget_prop_id;
  },
  data() {
    return {
      is_widget: false,
      showMenu: false,
      formInfo: {
        firstName: "",
        email: "",
        phone: "",
      },
    };
  },

  methods: {
    brochureSubmit(el) {
      console.log("formInput:::", el);
      let dataJson = [
        {
          Attribute: "FirstName",
          Value: el.target.elements.FirstName.value,
        },
        {
          Attribute: "EmailAddress",
          Value: el.target.elements.EmailAddress.value,
        },
        {
          Attribute: "Phone",
          Value: el.target.elements.phone.value,
        },
        {
          Attribute: "mx_Program",
          Value: "B.Com",
        },

        {
          Attribute: "mx_Elective",
          Value: " Accounting and Finance",
        },
      ];
      console.log(dataJson);
      axios
        .post(
          "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$rca456e8ab1c2865cfece22cda05cbb0c&secretKey=3c0ddb27e7dc562e5c6c08bb8c11b02a9019e041",
          dataJson,
          { cross: true }
        )
        .then(async() => {
          
          await fetch(`https://api.onlineyenepoya.com/media/B.Com_in_Accounting_and_Finance_63962.pdf`)
              .then((response) => response.blob())
              .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement("a");
                a.href = url;
                a.download = `BCom-in-Accounting-and-Finance.pdf`;
                a.click();
              });
          setTimeout(()=>{
            window.location.href = "/brochure/bba-thankyou";
          },2000)
        })
        .catch((error) => {
          // console.log(error.response.data.ExceptionMessage)
          console.log(error);
        });
    },
  },
    
};
</script>
BCom-in-Accounting-and-Finance.pdf
<style></style>
